@import url("./assets/styles/styles.module.scss");




html {
    height: 100%;
    
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    overflow-y: hidden;
    padding: 0;
    min-width: 100%;

  * {
    vertical-align: top;
  }
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

.scrollbar::-webkit-scrollbar {
    width: 2px;  
    background-color: #fff;
    border-radius: 15px;
}
.scrollbar::-webkit-scrollbar-thumb {
    background-color: #545450;
    border-radius: 15px;
}

.scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fff;
    background-color: #f5f5f5;
    margin-left: 1rem;
}

.line-bottom{
  border-bottom: 1px solid #dee2e6;
}

.firstCol{
  padding-left: 0 !important;
}