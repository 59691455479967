@font-face {
    font-family: 'Icon OneSix';
    src:  url('../fonts/icons.eot?g9bsoq');
    src:  url('../fonts/icons.eot?g9bsoq#iefix') format('embedded-opentype'),
      url('../fonts/icons.ttf?g9bsoq') format('truetype'),
      url('../fonts/icons.woff?g9bsoq') format('woff'),
      url('../fonts/icons.svg?g9bsoq#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Icon OneSix' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow:before {
    content: "\e900";
    color: #7d7d7d;
  }
  .icon-edit:before {
    content: "\e901";
    color: #7d7d7d;
  }
  .icon-note:before {
    content: "\e902";
    color: #7d7d7d;
  }

  .icon-duplicate:before {
    content: "\e903";
    color: #7d7d7d;
  }