//-------distance of component in table

.position-avatar {
  width: 1rem;
  padding: 0.75rem 0.5rem 0 0.5rem !important;
}

.position-status {
  width: 1rem;
  padding: 1.2rem 0.5rem 0 0.5rem !important ;
}
.position-iconEdit {
  width: 1rem;
  padding: 0.9rem 0 0 0.5rem !important;
}

.position-arrow {
  width: 1rem;
  padding: 0.9rem 0.5rem 0 0.5rem !important;
}

.position-trash {
  width: 1rem;
  padding: 0.75rem 0.5rem 0 0.5rem !important;
}

.position-text {
  min-width: 10rem;
  padding: 1rem 0.5rem !important;
}

.border-bottom {
  border-bottom: .5px solid rgba(128,128,128, .2);
}

//------------------------------------------
