@import "../../styles/table.scss";

.containerActionsElements {
  display: inline-flex;
  margin-bottom: 2.5rem;
  width: 100%;
  justify-content: space-between;
  padding-right: 1rem;
}
.modal {
  height: max-content;
}
.addButtomPosition {
  width: auto;
  height: 35px;
  margin-top: 0.5rem;
}
.searchInput {
  align-self: flex-start;
  justify-self: flex-start;
}
.avatar {
  width: 2rem !important;
  height: 2rem !important;
  span {
    font-size: 0.9rem !important;
  }
}

.containerTable {
  padding: 3rem 2rem 1rem 2rem !important;
  background: #fff;
  height: calc(100% - 69px - 77px);
  overflow-y: scroll;
}

.containerTable::-webkit-scrollbar {
  width: 0.2%;
  background-color: #fff;
  border-radius: 15px;
}
.containerTable::-webkit-scrollbar-thumb {
  background-color: #545450;

  border-radius: 15px;
}

.containerTable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #fff;
  background-color: #f5f5f5;
}

.status {
  content: " ";
  width: 1rem;
  height: 1rem;
  display: inline-block;

  border-radius: 50%;
  vertical-align: center;
  position: relative;
  top: 3px;
  background: #893837;
}

.status-active {
  background: #5f9139;
}

// styles in colums

.col1 {
  @extend .position-text;
}

.col2 {
  @extend .position-text;
}

.col3 {
  @extend .position-text;
}
.col4 {
  @extend .position-text;
}

.col5 {
  @extend .position-text;
}


.col6 {
  @extend .position-status;
}

.col7 {
  @extend .position-avatar;
}
.col8 {
  @extend .position-iconEdit;
}

.iconEdit {
  font-size: 24px;
  cursor: pointer;
}
