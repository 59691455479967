
@font-face{
    font-family: "Gotham Light";
    font-size: 16px;
    color:'#545450';
   // font-weight: 300;
    font-style: normal ;
    src:url("../fonts/Gotham-Light.ttf");

}


@font-face{
    font-family: "Roboto Light";
    font-size: 16px;
    color:'#545450';
   // font-weight: 300;
    font-style: normal ;
    src:url("../fonts/RobotoCondensed-Light.ttf");

}

